/* eslint-disable camelcase */
import resolveUrlForRecord from './resolveUrlForRecord'
import {
  LinkFragment_LinkExternalRecord,
  LinkFragment_LinkInternalRecord,
} from '../graphql/linkFragment.generated'
import {
  LinkWithTextAndImageFragment_LinkWithTextAndImageExternalRecord,
  LinkWithTextAndImageFragment_LinkWithTextAndImageInternalRecord,
} from '../graphql/linkWithTextAndImageFragment.generated'
import {
  LinkWithTextFragment_LinkWithTextExternalRecord,
  LinkWithTextFragment_LinkWithTextInternalRecord,
} from '../graphql/linkWithTextFragment.generated'
import { SiteConfig } from '../graphql/siteConfig'
import { MailWithTextFragment } from '../graphql/mailWithTextFragment.generated'
import { PhoneWithTextFragment } from '../graphql/phoneWithTextFragment.generated'

const fallback = {
  href: '',
  target: undefined,
}

const resolveLink = (
  siteConfig: SiteConfig,
  link:
    | LinkFragment_LinkExternalRecord
    | LinkFragment_LinkInternalRecord
    | LinkWithTextFragment_LinkWithTextExternalRecord
    | LinkWithTextFragment_LinkWithTextInternalRecord
    | LinkWithTextAndImageFragment_LinkWithTextAndImageExternalRecord
    | LinkWithTextAndImageFragment_LinkWithTextAndImageInternalRecord
    | MailWithTextFragment
    | PhoneWithTextFragment,
) => {
  switch (link.__typename) {
    case 'LinkExternalRecord':
    case 'LinkWithTextExternalRecord':
    case 'LinkWithTextAndImageExternalRecord':
      return {
        href: link.url || '',
        target: link.openInNewTab ? ('_blank' as const) : undefined,
      }
    case 'LinkInternalRecord':
    case 'LinkWithTextInternalRecord':
    case 'LinkWithTextAndImageInternalRecord': {
      const path = link.page
        ? resolveUrlForRecord(siteConfig, link.page)
        : undefined

      return path
        ? {
            href: link.sectionAnchor ? `${path}#${link.sectionAnchor}` : path,
          }
        : fallback
    }
    case 'MailWithTextRecord':
      return {
        href: `mailto:${link.mail}` || '',
        text: link.text || '',
      }
    case 'PhoneWithTextRecord':
      return {
        href: `tel:${link.phone}` || '',
        text: link.text || '',
      }
    default:
      return fallback
  }
}

export default resolveLink
